import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import Row from "../components/bootstrap/row"

import AwardCategoryShortlist from "../components/award-category-shortlist"
import ShortlistOrganisation from "../components/shortlist-organisation"
import PrimaryButton from "../components/primary-button"

const WinnersPage = () => (
  <Background>
    <Layout>
      <Seo title="Winners" />
      <Container>
        <PageTitle text="Winners" />
        <p style={{
          textTransform: `uppercase`,
          color: `#fff`,
          fontWeight: `bold`,
          textAlign: `center`,
          maxWidth: `750px`,
          margin: `auto`,
          marginBottom: `50px`,
        }} hidden>Congratulations to all organisations who have made it to the shortlist.</p>

        <Row>
          <div className="col-lg">
            <AwardCategoryShortlist title="Improving Spaces" subtitle="Creating a Great Place to Work" gridColor="#d23464" icon="computer-desk">
              <ShortlistOrganisation organisation="London School of Economics and Political Science" project="Future Ways of Working Programme: Improving Spaces" logo="hmrc" />
            </AwardCategoryShortlist>
          </div>
          <div className="col-lg">
            <AwardCategoryShortlist title="Future Focused and Sustainable Property" gridColor="#30c4bf" icon="office">
              <ShortlistOrganisation organisation="National Probation Service" project="Redfern Probation Office, Manchester" logo="hmrc" />
            </AwardCategoryShortlist>
          </div>
        </Row>

        <br /><br />

        <Row>
          <div className="col-lg">
            <AwardCategoryShortlist title="Digital Transformation" subtitle="Enabling New Ways of Working" gridColor="#30c4bf" icon="arrows-cog">
              <ShortlistOrganisation organisation="HM Revenue & Customs" project="Open Banking Payment Innovation" logo="rchnhs" />
            </AwardCategoryShortlist>
          </div>
          <div className="col-lg">
            <AwardCategoryShortlist title="Automation, Artifical Intelligence and Machine Learning" gridColor="#d23464" icon="brain">
              <ShortlistOrganisation organisation="Sandwell & West Birmingham NHS Trust" project="Merge Clinical Review AI (Artificial Intelligence)" logo="hmrc" />
            </AwardCategoryShortlist>
          </div>
        </Row>

        <br /><br />
        
        <Row>
          <div className="col-lg">
            <AwardCategoryShortlist title="Harnessing the Value of Data" gridColor="#d23464" icon="binary">
              <ShortlistOrganisation organisation="Wakefield District Health and Care Partnership" project="Using System Data to Underpin Service Redesign" logo="dfe" />
            </AwardCategoryShortlist>
          </div>
          <div className="col-lg">
            <AwardCategoryShortlist title="Small-scale, Big Impact" gridColor="#30c4bf" icon="inward-arrows-square">
              <ShortlistOrganisation organisation="Eastman Dental Hospital, University College London Hospitals" project="Virtual Platform to Improve Children's Access to Dental Care" logo="rchnhs" />
            </AwardCategoryShortlist>
          </div>
        </Row>
        
        <br /><br />

        <Row>
          <div className="col-lg">
            <AwardCategoryShortlist title="Building Collaborative Communities" gridColor="#30c4bf" icon="circle-people">
              <ShortlistOrganisation organisation="Liverpool School of Tropical Medicine" project="Liverpool Vaccine Equity Project" logo="dfe" />
            </AwardCategoryShortlist>
          </div>
          <div className="col-lg">
            <AwardCategoryShortlist title="Putting People First" gridColor="#d23464" icon="inspire">
              <ShortlistOrganisation organisation="Babergh & Mid Suffolk District Councils" project="Wellbeing Support Programme" logo="hmrc" />
            </AwardCategoryShortlist>
          </div>
        </Row>

        <br /><br />

        <Row>
          <div className="col-lg">
            <AwardCategoryShortlist title="Championing Continuous Improvement" gridColor="#d23464" icon="three-up-arrows">
              <ShortlistOrganisation organisation="HM Land Registry" project="Local Land Charges Programme" logo="hmrc" />
            </AwardCategoryShortlist>
          </div>
          <div className="col-lg">
            <AwardCategoryShortlist title="Outstanding Smarter Working Leadership" gridColor="#30c4bf" icon="leader">
              <ShortlistOrganisation organisation="Department for Business & Trade" project="Our Leaders as Smarter Working Drivers" logo="nhsemployers" />
            </AwardCategoryShortlist>
          </div>
        </Row>

        <h2 className="my-5" style={{
          color: `#fff`,
          fontFamily: `Avenir`,
          fontWeight: `900`,
          fontSize: `2.5rem`,
          textAlign: `center`,
          margin: `3rem 0`
        }}>Judges Choice</h2>

        <Row>
          <div className="col-lg">
            <AwardCategoryShortlist title="Special Recognition" gridColor="#d23464" icon="trophy">
              <ShortlistOrganisation organisation="Swindon Borough Council" />
            </AwardCategoryShortlist>
          </div>
          <div className="col-lg">
            <AwardCategoryShortlist title="Beyond Smarter Working" gridColor="#d23464" icon="star">
              <ShortlistOrganisation organisation="Office for National Statistics" />
            </AwardCategoryShortlist>
          </div>
        </Row>

        <br /><br />
        
        <div className="text-center">
          <PrimaryButton to="/shortlist/" text="View the Shortlist" />
        </div>

        <br /><br /><br /><br />

      </Container>
    </Layout>
  </Background>
)

export default WinnersPage
